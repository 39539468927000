<template>
  <v-row no-gutters>
    <v-col md="3">
      <text-field
        v-model.trim="search"
        class="search-field"
        outlined
        label="Search Member"
        placeholder="Type member's name or email@"
        maxlength="255"
      />
    </v-col>
    <v-col class="d-flex justify-end align-center">
      <primary-button
        short
        @click="newMemberModal"
      >
        Invite Member
      </primary-button>
    </v-col>
    <v-col md="12">
      <data-table
        v-model="selectedRows"
        class="mt-5"
        :search="search"
        show-select
        item-key="member_card_id"
        :loading="loading"
        :items="members"
        :headers="headers"
        :items-per-page="membersMetaPerPage"
        :hide-footer="true"
        :server-items-length="membersMetaTotal"
        :page="membersMetaCurrentPage"
        :custom-columns="['item.time_tracking_status', 'item.member']"
      >
        <template v-slot:custom-render-item.time_tracking_status="{ item }">
          <div class="d-flex align-center">
            <v-icon
              x-small
              class="dot-icon mr-2"
              :class="isTimeTrackingDisabled(item) ? 'grey-dot' : `success-dot`"
            >
              fiber_manual_record
            </v-icon>
            {{ isTimeTrackingDisabled(item) ? 'Disabled' : 'Enabled' }}
          </div>
        </template>
        <template v-slot:custom-render-item.member="{ item }">
          <div class="d-flex align-center">
            <div class="custom-member">
              <img
                class="custom-member-img"
                :src="item.image"
                :alt="item.name"
                :title="item.name"
              >
            </div>
            <span class="custom-member__name">{{ item.name }}</span>
          </div>
        </template>
        <template v-slot:item.project_roles="{ item: { project_roles } }">
          <template v-if="project_roles.length">
            <div v-for="(role, index) in project_roles" :key="index">
              {{ role }}
            </div>
          </template>
          <template v-else>
            Project-user
          </template>
        </template>
        <template v-slot:actions-menu="{ item }">
          <data-table-actions>
            <data-table-action-item
              icon="drive_file_rename_outline"
              @click="editMember(item.member_card_id)"
            />
          </data-table-actions>
        </template>
        <template v-slot:footer>
          <div v-show="members.length" class="v-data-footer">
            <base-pagination
              v-model="params.page"
              :pagination-meta="membersMeta"
              :has-pagination-pages="false"
              :has-counter-items-on-page="true"
              @change-page="changePaginationPage"
            />
          </div>
        </template>
      </data-table>
    </v-col>
    <table-checked-bar v-model="snackbar">
      <v-row no-gutters>
        <v-col>
          <secondary-button small @click="confirmInactive = true">
            Move
          </secondary-button>
        </v-col>
        <v-col>
          <secondary-button
            small
            @click="confirmStatus = true"
          >
            Delete
          </secondary-button>
        </v-col>
        <v-col>
          <text-button
            small
            @click="resetSelectedRows"
          >
            Cancel
          </text-button>
        </v-col>
      </v-row>
    </table-checked-bar>
    <custom-dialog
      v-if="newStatus"
      v-model="newStatus"
      title="Add Member"
      :loading="loadingInvite"
      btn-text="Send Invitation"
      @save="saveNewMember"
    >
      <validation-observer ref="observer">
        <v-form ref="invite">
          <v-row class="flex-column">
            <v-col md="12" class="py-0">
              <validation-provider
                v-slot="{ errors }"
                name="email"
                rules="required|email|max:255"
              >
                <text-field
                  v-model="formData.invited_email"
                  full-size
                  label="Email"
                  type="email"
                  outlined
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <!--            <v-col md="12" class="pt-0">-->
            <!--              <b class="project-title">-->
            <!--                Projects-->
            <!--              </b>-->
            <!--            </v-col>-->
            <v-col md="12">
              <v-row
                v-for="(item, index) in formData.init_membership"
                :key="index"
                class="flex-column"
              >
                <v-col class="py-0 d-flex align-center">
                  <validation-provider
                    v-slot="{ errors }"
                    name="project"
                    rules="required"
                    style="width: 100%"
                  >
                    <custom-select
                      ref="select"
                      v-model="item.project_id"
                      style="width: 100%"
                      label="Project"
                      item-value="id"
                      item-text="name"
                      item-disabled="disabled"
                      no-data-text="No projects found"
                      :loading="projects.loading"
                      outside
                      project
                      :items="projects.items"
                      :error-messages="errors"
                    />
                  </validation-provider>
                  <text-button
                    v-if="index > 0"
                    class="close-btn"
                    small
                    @click="removeProject(index)"
                  >
                    <v-icon small>
                      close
                    </v-icon>
                  </text-button>
                </v-col>
                <v-col md="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="role"
                    rules="required"
                  >
                    <autocomplete
                      v-model="item.role_alias"
                      label="Role"
                      item-text="name"
                      item-value="alias"
                      :items="roles"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-col md="12" class="pl-0">
                <text-button
                  class="add-btn"
                  @click="addProject"
                >
                  <v-icon class="primary-icon mr-2">
                    add_circle
                  </v-icon>
                  Add project
                </text-button>
              </v-col>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </custom-dialog>
    <custom-dialog
      v-if="editStatus"
      v-model="editStatus"
      :content-load="loadingModal"
      title="Edit Member"
      @save="saveEditedMember"
    >
      <validation-observer ref="observer">
        <v-form>
          <v-row>
            <v-col md="12" class="py-0">
              <label class="member-title">
                Member
              </label>
              <v-row class="member-row">
                <img
                  v-if="formDataEdit.image"
                  class="avatar"
                  :src="formDataEdit.image"
                  alt=""
                >
                <img
                  v-else
                  class="avatar"
                  src="@/static/icons/avatar.png"
                  alt=""
                >
                <p class="d-flex align-center user-name">
                  {{ formDataEdit.name }}
                </p>
              </v-row>
            </v-col>
            <v-col md="12" class="py-0">
              <validation-provider
                v-slot="{ errors }"
                name="email"
                rules="required|email|max:255"
              >
                <text-field
                  v-model="formDataEdit.invited_email"
                  full-size
                  label="Email"
                  type="email"
                  maxlength="255"
                  outlined
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <!--            <v-col md="12" class="pt-0">-->
            <!--              <b class="project-title">-->
            <!--                Projects-->
            <!--              </b>-->
            <!--            </v-col>-->
            <v-col md="12">
              <v-row
                v-for="(item, index) in formDataEdit.membership"
                :key="index"
              >
                <v-col md="10" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="project"
                    rules="required"
                  >
                    <autocomplete
                      v-model="item.project_id"
                      label="Project"
                      item-text="name"
                      item-value="id"
                      item-disabled="disabled"
                      no-data-text="No projects found"
                      outside
                      :items="projects.items"
                      :loading="projects.loading"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col md="2" class="py-0 d-flex justify-center align-center">
                  <text-button
                    v-if="formDataEdit.membership.length > 1"
                    class="close-btn"
                    small
                    @click="removeProject(index, 'edit')"
                  >
                    <v-icon small>
                      close
                    </v-icon>
                  </text-button>
                </v-col>
                <v-col md="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="role"
                    rules="required"
                  >
                    <autocomplete
                      v-model="item.role_alias"
                      label="Role"
                      item-text="name"
                      item-value="alias"
                      :items="roles"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <!--                <v-col md="12" class="py-0">-->
                <!--                  <autocomplete-->
                <!--                    v-model="item.trekking_time"-->
                <!--                    label="Tracking time"-->
                <!--                    item-text="name"-->
                <!--                    item-value="alias"-->
                <!--                    :items="['Enable', 'Disable']"-->
                <!--                  />-->
                <!--                </v-col>-->
              </v-row>
              <v-col md="12" class="pl-0">
                <text-button
                  class="add-btn"
                  @click="addProject('edit')"
                >
                  <v-icon class="primary-icon mr-2">
                    add_circle
                  </v-icon>
                  Add project
                </text-button>
              </v-col>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </custom-dialog>
    <confirm-modal
      v-model="confirmStatus"
      title="Delete Member"
      @save="deleteMembers"
    >
      Are you sure you want to delete these members?
    </confirm-modal>
    <confirm-modal
      v-model="confirmInactive"
      title="Move Member"
      @save="addToInactive"
    >
      Are you sure you want to move these members?
    </confirm-modal>
  </v-row>
</template>

<script>
import { MEMBERS } from '@/views/Member/constants';
import TextField from '@/components/ui/TextField/TextField';
import PrimaryButton from '@/components/ui/Buttons/PrimaryButton';
import DataTable from '@/components/collections/DataTable/DataTable';
import DataTableActions from '@/components/collections/DataTableActions/DataTableActions';
import DataTableActionItem from '@/components/collections/DataTableActions/DataTableActionItem';
import TableCheckedBar from '@/components/ui/Snackbars/TableCheckedBar';
import SecondaryButton from '@/components/ui/Buttons/SecondaryButton';
import TextButton from '@/components/ui/Buttons/TextButton';
import CustomDialog from '@/components/collections/Dialog/CustomDialog';
import Autocomplete from '@/components/ui/Autocomplete/Autocomplete';
import { apiCall } from '@/services/axios';
import CustomSelect from '@/components/ui/CustomSelect/CustomSelect';
import ConfirmModal from '@/components/collections/Dialog/ConfirmModal';
import BasePagination from '@/components/ui/Pagination/BasePagination';
import queryParamsMixin from '@/mixins/queryParamsMixin';
import { debounce, updateParams } from '@/helpers';

const defaultProject = [
  {
    role_alias: null,
    project_id: null,
  },
];

export default {
  name: 'Active',
  components: {
    ConfirmModal,
    CustomSelect,
    Autocomplete,
    CustomDialog,
    TextButton,
    SecondaryButton,
    TableCheckedBar,
    DataTableActionItem,
    DataTableActions,
    DataTable,
    PrimaryButton,
    TextField,
    BasePagination,
  },
  mixins: [queryParamsMixin],
  data() {
    return {
      search: null,
      members: [],
      headers: MEMBERS,
      snackbar: false,
      roles: [],
      selectedRows: [],
      newStatus: false,
      editStatus: false,
      formData: {
        invited_email: '',
        init_membership: [...defaultProject],
      },
      formDataEdit: {
        invited_email: '',
        membership: [...defaultProject],
      },
      projects: {
        items: [],
        loading: false,
      },
      loading: false,
      loadingModal: false,
      loadingInvite: false,
      params: {
        is_active: '1',
        orderBy: 'created_at',
        sortedBy: 'desc',
        page: 1,
      },
      confirmStatus: false,
      confirmInactive: false,
      selectedMemberId: null,
    };
  },
  computed: {
    selectedWorkspaceId() {
      return this.$store.getters.getSelectedWorkspace.id;
    },
    membersMeta() {
      return this.$store.getters.getMembersMeta;
    },
    membersMetaPerPage() {
      return this.$store.getters.getMembersMeta?.per_page;
    },
    membersMetaTotal() {
      return this.$store.getters.getMembersMeta?.total;
    },
    membersMetaCurrentPage() {
      return this.$store.getters.getMembersMeta?.current_page;
    },
    getNavigationProjects() {
      return this.$store.getters.getnavigationProjects;
    },
  },
  watch: {
    getNavigationProjects(projects) {
      this.projects.items = projects;
    },
    selectedRows: {
      deep: true,
      handler(val) {
        this.snackbar = !!val.length;
      },
    },
    formData: {
      deep: true,
      handler(val) {
        this.addDisabled(val, 'init');
      },
    },
    formDataEdit: {
      deep: true,
      handler(val) {
        this.addDisabled(val);
      },
    },
    search(val) {
      if (val === undefined || val === null) return;

      if (val.length > 255) {
        this.search = val.slice(0, 255);
      }

      this.useDebounce(val);
    },
    newStatus(val) {
      if (!val) {
        this.formData.init_membership = [
          {
            role_alias: null,
            project_id: null,
          },
        ];
        this.formData.invited_email = '';
        this.$refs.invite.reset();
      }
    },
    params: {
      handler(data) {
        this.setQueryParams({
          search: data.search,
          page: data.page,
        });
      },
      deep: true,
    },
  },
  created() {
    this.useDebounce = debounce((searchValue) => {
      this.handleSearch(searchValue);
    }, 600);
  },
  async mounted() {
    if (Object.keys(this.$route.query).length > 0) {
      this.updateDataByQueryParams(this.$route.query);
      this.search = this.$route.query.search;
    }

    if (!this.$route.query?.search) {
      await this.initialLoad();
    }

    this.$store.subscribe((mutation) => {
      if (mutation.type === 'updateSelectedWorkspace') {
        this.initialLoad();
      }
    });
  },
  methods: {
    isTimeTrackingDisabled(item) {
      return (!!item.project_roles.length
        && item.project_roles.length === 1
        && item.project_roles[0] === 'Project-client')
        || !item.time_tracking_status;
    },
    handleSearch(searchValue) {
      this.params = updateParams(searchValue, this.params);
      this.initialLoad();
    },
    async initialLoad() {
      this.loading = true;
      await this.$store.dispatch('GET_ACTIVE_MEMBERS', {
        id: this.selectedWorkspaceId,
        params: this.params,
      })
        .then(() => {
          this.members = this.$store.getters.getMembers;
          this.loading = false;
          console.log(123123, this.members);
        });
      await this.getProjectsAndRoles();
    },
    changePaginationPage(page) {
      this.params.page = page;
      this.loading = true;
      this.$store.dispatch('GET_ACTIVE_MEMBERS', {
        id: this.selectedWorkspaceId,
        params: this.params,
      })
        .then(() => {
          this.members = this.$store.getters.getMembers;
          this.loading = false;
        });
    },
    foo(val) {
      console.log(val);
    },
    resetSelectedRows() {
      this.selectedRows = [];
    },
    resetData() {
      this.formData = {
        invited_email: '',
        init_membership: [
          {
            role_alias: null,
            project_id: null,
          },
        ],
      };
    },
    saveNewMember() {
      const observerRef = this.$refs.observer;
      observerRef.validate().then((isValid) => {
        if (isValid) {
          this.loadingInvite = true;
          this.$store.dispatch('INVITE_MEMBER', {
            id: this.selectedWorkspaceId,
            member: { ...this.formData },
          }).then(() => {
            this.initialLoad();
            this.newStatus = false;
            this.loadingInvite = false;
            observerRef.reset();
          });
        }
      });
    },
    saveEditedMember() {
      const observerRef = this.$refs.observer;
      observerRef.validate().then((isValid) => {
        if (isValid) {
          this.$store.dispatch('EDIT_MEMBER', {
            id: this.selectedWorkspaceId,
            memberId: this.selectedMemberId,
            member: { is_active: true, ...this.formDataEdit },
          }).then(() => {
            this.initialLoad();
            this.editStatus = false;
            observerRef.reset();
          });
        }
      });
    },
    newMemberModal() {
      this.newStatus = true;
      this.getProjectsAndRoles();
    },
    async editMember(val) {
      this.editStatus = true;
      this.loadingModal = true;
      await this.getProjectsAndRoles();
      this.selectedMemberId = val;

      try {
        await apiCall('get', `workspaces/${this.selectedWorkspaceId}/member-cards/${val}`)
          .then((res) => {
            console.log(res, 'formDataEdit');
            this.formDataEdit = {
              image: res.data.data.image,
              name: res.data.data.name,
              invited_email: res.data.data.email,
              color: res.data.data.color_id,
              membership: res.data.data.projects?.length
                ? res.data.data.projects : [...defaultProject],
            };

            this.addDisabled(this.formDataEdit);
          });
      } catch (e) {
        console.log(e);
      }

      this.loadingModal = false;
    },
    addProject(val) {
      const values = {
        role_alias: null,
        project_id: null,
      };

      if (val === 'edit') {
        this.formDataEdit.membership.push(values);
      } else {
        this.formData.init_membership.push(values);
      }
    },
    async getProjectsAndRoles() {
      const res = await apiCall('GET',
        `workspaces/${this.selectedWorkspaceId}/member-cards/invite-info`);
      this.projects.items = res.data.projects;
      this.roles = res.data.roles;
    },
    removeProject(index, val) {
      const projects = this.projects.items;

      if (val) {
        this.formDataEdit.membership.splice(index, 1);
      } else {
        const projectId = this.formData.init_membership[index].project_id;

        if (projectId) {
          projects.forEach((item) => {
            if (item.id === projectId) {
              this.$set(item, 'disabled', false);
            }
          });
          this.projects.items = projects;
        }

        this.formData.init_membership.splice(index, 1);
      }
    },
    deleteMembers() {
      const ids = this.selectedRows.map((item) => item.member_card_id);
      this.$store.dispatch('DELETE_MEMBERS', {
        id: this.selectedWorkspaceId,
        members: { member_card_ids: ids },
      }).then(async () => {
        this.resetSelectedRows();
        this.confirmStatus = false;
        await this.initialLoad();
      });
    },
    addDisabled(val, type) {
      let exceptionIds;

      if (type === 'init') {
        exceptionIds = val.init_membership.map((item) => item.project_id);
      } else {
        exceptionIds = val.membership.map((item) => item.project_id);
      }

      const projects = this.projects.items;

      if (projects.length) {
        projects.forEach((item, index) => {
          if (exceptionIds.includes(item.id) && item.id) {
            this.$set(this.projects.items[index], 'disabled', true);
          } else {
            this.$set(this.projects.items[index], 'disabled', false);
          }
        });
      }
    },
    // async getProjectsRole() {
    //   const res = await apiCall('get', 'roles/get-project-roles');
    //   this.roles = res.data.data;
    //
    //   return res.data.data;
    // },
    addToInactive() {
      this.selectedRows.map(async (item) => {
        try {
          await apiCall('post', `workspaces/${this.selectedWorkspaceId}/member-cards/${item.member_card_id}`, { is_active: false })
            .then(() => {
              this.initialLoad();
              this.snackbar = false;
            });
        } catch (err) {
          console.log(err);
        }
      });
      this.confirmInactive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .custom-member {
    display: flex;
    align-items: center;

    &-img {
      width: 28px;
      height: 28px;
      border: 2px solid $white;
      border-radius: 11px;
      margin-right: 8px;
    }
    &__name{
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .project-title {
    font-size: $type-s;
  }

  .close-btn {
    width: 20px;
    min-width: 20px !important;
    height: 20px;
  }

  .add-btn {
    margin-left: -15px;
    &:hover {
      background-color: transparent !important;
      &:before {
        display: none;
      }
    }

  }
  .search-field {
    ::v-deep .v-input__slot {
      min-width: 280px;
    }
  }
  .avatar {
    border-radius: 21px;
    width: 56px;
    min-height: 56px;
    max-height: 56px;
    margin-right: 16px;
  }
  .member-title{
    font-size: $type-xs;
    margin-bottom: $spacing-2;
    display: block;
    color: $grey;
  }
  .member-row {
    flex-wrap: nowrap;
    margin: 0;
    margin-bottom: 16px;

    p {
      color: $black;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .user-name{
    margin-bottom: 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
  }
</style>
